<template>
  <div class="card card-light bg-shadow p-4">
    <p>
      <span class="text-bold text-secondary small" v-if="records">
        <i class="fas fa-refresh cursor-pointer m-0 mt-2 float-right" v-on:click="getReport"></i>
        <input type="number" min="20" max="100" class="form-control form-frequency float-right m-0 mx-3" :placeholder="'Trend frequency'" v-model="filters.frequency" v-if="chart == 'line'" v-tooltip="'Changes the frequency of the data. The higher the number, the more points will be plotted on the chart.'">
      </span>
      <b>{{title}}</b><br>
      <small v-if="subtitle">{{subtitle}}</small>
    </p>
    <div v-if="loading" class="text-center small py-5 px-2">
      <h1>
        <i class="fas fa-spinner animate-spin"></i>
      </h1>
      Loading ticket data<br>
      <small class="text-muted">This might take a while</small>
    </div>
    <div v-else-if="!loading && errors" class="text-center small py-5 px-2">
      <h1>
        <i class="fa fa-exclamation-circle"></i>
      </h1>
      Something went wrong<br>
      <small v-on:click="getReport" class="cursor-pointer text-primary">Try again</small>
    </div>
    <div v-else-if="!loading && !errors && !records" class="text-center small py-5 px-2">
      <h1>
        <i class="fa fa-download"></i>
      </h1>
      This report needs to be generate data first<br>
      <small v-on:click="getReport" class="cursor-pointer text-primary">Generate data</small>
    </div>
    <div v-else>
      <DoughnutChart
        v-if="chart == 'doughnut'"
        :dataset-id-key="'label'"
        :chart-data="$buildTotalsChartData(records, false)"
        :chart-id="`${type}-ticket-totals`"
      />
      <BarChart
        v-else-if="chart == 'bar'"
        :dataset-id-key="'label'"
        :chart-data="$buildTotalsChartData(records, false)"
        :chart-id="`${type}-ticket-totals`"
      />
      <LineChart
        v-else-if="chart == 'line'"
        :dataset-id-key="'label'"
        :chart-data="$buildTotalsChartData(records, true, filters.frequency)"
        :chart-id="`${type}-ticket-totals`"
      />
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import DoughnutChart from '../Charts/DoughnutChart.vue'
  import BarChart from '../Charts/BarChart.vue'
  import LineChart from '../Charts/LineChart.vue'

  export default {
    props: {
      filters: Object,
      type: String,
      title: String,
      subtitle: String,
      chart: String,
      limiter: Boolean
    },
    components: {
      DoughnutChart,
      BarChart,
      LineChart
    },
    data() {
      return {
        user: this.$userData.user,
        loading: false,
        errors: false,
        records: null
      }
    },
    watch: {
      'filters.dateRange': function () {
        this.getReport()
      }
    },
    methods: {
      getReport: async function(){
        this.loading = true
        this.errors = false
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/dashboard/ticketTotals`,
            {
              startsAt: moment(this.filters.dateRange[0]).startOf('day'),
              endsAt: moment(this.filters.dateRange[1]).startOf('day'),
              type: this.type
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          if(response.data){
            if(this.limiter){
              this.records = (response.data.records || []).slice(0,10)
              this.records.push({
                record: { _id: "others", name: "Others" },
                total: (response.data.records || []).slice(10,1000).map(item => item.total).reduce((prev, curr) => prev + curr, 0)
              })
              this.records = this.records.filter(r => r.total > 0)
            }
            else if(this.type == 'trend'){
              this.records = response.data.records
            }
            else{
              this.records = response.data.records.filter(r => r.total > 0)
            }
          }
          this.loading = false
        } catch (error) {
          console.log(error)
          this.errors = error
          this.loading = false
        }
      }
    },
    mounted: function() {
      this.getReport();
    }
  }
</script>
