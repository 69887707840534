<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/dashboard">Dashboard</a>
    </div>
    <div class="row px-5">
      <div class="col-12 mb-4 p-0 row no-gutters justify-content-end">
        <div class="col col-md-3 col-sm-6 px-3">
          <v-date-picker
            v-model="filters.dateRange"
            :placeholder="`${formatDate(filters.dateRange[0])} - ${formatDate(filters.dateRange[1])}`"
            :range="true"
            input-class="vue-datepicker w-100 text-muted border-0 px-2 my-1 small"
            class="w-100 form-control p-1"
          >
          </v-date-picker>
        </div>
      </div>
    </div>
    <div class="row dashboard-row px-5 mb-4">
      <div class="dashboard-col col-12 mb-4">
        <TicketTotals
          v-if="filters.dateRange"
          :filters="filters"
          :title="`Ticket Count Trend as of ${formatDate(new Date())}`"
          :subtitle="`From ${formatDate(filters.dateRange[0])} to ${formatDate(filters.dateRange[1])}`"
          :type="'trend'"
          :chart="'line'"
          :limiter="false"
        />
      </div>
      <div class="dashboard-col col-lg-3 col-md-6 col-sm-12 mb-4">
        <TicketTotals
          v-if="filters.dateRange"
          :filters="filters"
          :title="'Ticket Count per Year'"
          :subtitle="'Total tickets grouped per year'"
          :type="'year'"
          :chart="'doughnut'"
          :limiter="true"
        />
      </div>
      <div class="dashboard-col col-lg-3 col-md-6 col-sm-12 mb-4">
        <TicketTotals
          v-if="filters.dateRange"
          :filters="filters"
          :title="'Ticket Count per Segment'"
          :subtitle="'Total tickets divided into segments'"
          :type="'segment'"
          :chart="'doughnut'"
          :limiter="false"
        />
      </div>
      <div class="dashboard-col col-lg-6 col-md-12 mb-4">
        <TicketTotals
          v-if="filters.dateRange"
          :filters="filters"
          :title="'Ticket Count per Client'"
          :subtitle="'Top 10 clients with the most tickets'"
          :type="'client'"
          :chart="'bar'"
          :limiter="true"
        />
      </div>
      <div class="dashboard-col col-lg-3 col-md-3 col-sm-12 mb-4">
        <TicketTotals
          v-if="filters.dateRange"
          :filters="filters"
          :title="'Ticket Count per Status'"
          :subtitle="'Total tickets divided into statuses'"
          :type="'status'"
          :chart="'doughnut'"
          :limiter="false"
        />
      </div>
      <div class="dashboard-col col-lg-3 col-md-3 col-sm-12 mb-4">
        <TicketTotals
          v-if="filters.dateRange"
          :filters="filters"
          :title="'Ticket Count per Severity'"
          :subtitle="'Total tickets divided into severities'"
          :type="'severity'"
          :chart="'doughnut'"
          :limiter="false"
        />
      </div>
      <div class="dashboard-col col-lg-3 col-md-3 col-sm-12 mb-4">
        <TicketTotals
          v-if="filters.dateRange"
          :filters="filters"
          :title="'Ticket Count per Classification'"
          :subtitle="'Top 10 classifications with the most tickets'"
          :type="'issueClassification'"
          :chart="'doughnut'"
          :limiter="true"
        />
      </div>
      <div class="dashboard-col col-lg-3 col-md-3 col-sm-12 mb-4">
        <TicketTotals
          v-if="filters.dateRange"
          :filters="filters"
          :title="'Ticket Count per Business Unit'"
          :subtitle="'Top 10 business units with the most tickets'"
          :type="'bunit'"
          :chart="'doughnut'"
          :limiter="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import TicketTotals from './Components/TicketTotals.vue'

  export default {
    components: {
      TicketTotals
    },
    data() {
      return {
        filters: {
          dateRange: [
            moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
            moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
          ],
          frequency: 30
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      formatDate: function(date){
        return moment(date, 'YYYY/MM/DD HH:mm:ss').format("MMM DD, YYYY")
      }
    },
    mounted: function() {
    }
  }
</script>
