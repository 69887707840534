<template>
  <div>
    <Bar
      :dataset-id-key="datasetIdKey"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :height="230"
    />
  </div>
</template>

<script>
  import { Bar } from 'vue-chartjs/legacy'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

  export default {
    props: {
      chartData: {
        type: Object,
        default: () => {}
      },
      chartId: {
        type: String,
        default: 'bar-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      }
    },
    components: {
      Bar
    },
    data() {
      return {
        chartOptions: {
          plugins: {
            legend: {
              display: false,
              position: 'bottom'
            }
          },
          scales:{
            x: { display: false },
            y: { ticks: { stepSize: Math.ceil((this.chartData.datasets[0].data[0] / 10) / 10) * 10 } },
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    }
  }
</script>
